.profile {
  .user-profile {
    height: 100%;
    display: flex;
    padding: 1.5rem;
    border-radius: 20px;
    align-items: center;
    flex-direction: column;
    background-color: #fff;
    justify-content: space-between;
    width: 40rem;
    margin: auto;
    gap: 1rem;

    .image-container {
      flex: 1;
      display: flex;
      position: relative;
      padding-right: 1rem;
      align-items: center;
      justify-content: center;

      .profile-update {
        bottom: 1rem;
        display: flex;
        right: 1.5rem;
        cursor: pointer;
        border-radius: 50%;
        position: absolute;
        align-items: center;
        justify-content: center;
        background-color: #00a8ff;

        label {
          width: 4rem;
          height: 4rem;
          display: flex;
          cursor: pointer;
          align-items: center;
          justify-content: center;
        }
      }

      img {
        width: 280px;
        height: 280px;
        object-fit: cover;
        border-radius: 50%;
        object-position: center;
      }
    }

    .profile-container {
      flex: 2;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 2rem;

        h3 {
          margin: 0;
          padding: 0;
          font-size: 1rem;
          color: #b4b4b4;
          font-weight: bold;
        }

        .buttons-container {
          gap: 10px;
          display: flex;
          align-items: center;

          Button {
            border: none;
            font-size: 1rem;
            padding: 5px 15px;
            text-transform: capitalize;
            background-color: #00a8ff;
          }

          Button.cancel-button {
            background-color: #a2a4a6;
          }
        }
      }

      .inline-group {
        display: flex;
        align-items: center;
        justify-content: space-between;

        label {
          margin: 0;
          padding: 0;
          flex: 0 0 10rem;
        }

        input,
        select {
          border-radius: 0;
          border-top: none;
          border-left: none;
          border-right: none;

          &:focus {
            box-shadow: none;
          }
        }

        .multiple-select {
          width: 100%;
        }
      }
    }
  }
}
