.global-form-input.grey-bg {
    flex: 1;

    label {
        font-weight: 500;
    }

    select.form-select {
        height: 40px;
        border-radius: 8px;
        background-color: #eee !important;
    }

    input.form-control {
        height: 40px;
        border-radius: 8px;
        background-color: #eee !important;
    }


    textarea.form-control {
        border-radius: 8px;
        background-color: #eee !important;
    }
}