// GLOBAL SCSS.
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&display=swap');

$mainColor: #168a3e;
$lightMainColor: #97e09b;

html * {
  font-family: 'Ubuntu', sans-serif;
}

input.form-control {
  &:focus {
    border-color: $mainColor;
    box-shadow: 0 0 0 0.25rem $lightMainColor;
  }
}

.global-form-input {
  position: relative;

  input,
  textarea {
    font-size: 13px;
    padding: 10px 20px;
    border-radius: 8px;
    background-color: #EAEDF0 !important;

    &:-internal-autofill-selected {
      background-color: #EAEDF0 !important;
    }

    &:focus,
    &:active {
      box-shadow: none;
      border-color: #022BFF;
    }

    &::placeholder {
      font-size: 13px;
    }
  }

  .eye-icon {
    margin: 0;
    width: 25px;
    right: 12px;
    bottom: 14px;
    height: 13px;
    margin: auto;
    cursor: pointer;
    position: absolute;

    &.hide {
      margin: 0;
      right: 12px;
      width: 25px;
      height: 8px;
      bottom: 14px;
    }
  }
}

.global-form-input-check {
  .form-check {
    margin: 0;
  }
}

.container-fluid {
  margin: auto;
  max-width: 1440px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

#popover-table-action {
  .button-actions {
    gap: 8px;
    display: flex;
    padding: 5px 14px;
    flex-direction: column;

    button {
      padding: 0;
      border: none;
      color: #000;
      font-size: 12px;
      background-color: transparent;
    }
  }
}

.modal-content {
  overflow: hidden !important;
  border-radius: 1.5rem !important;
}

@media only screen and (max-width: 969px) {
  .global-form-input {
    .left-icon {
      height: 56px;
    }

    input {
      padding: 15px 15px;
    }

    .eye-icon.hide {
      bottom: 15px;
    }
  }
}