.successModal {
    border-radius: 20px;

    .header {
        border: none;
        align-items: center;
        justify-content: center;
        background-color: #fff;

        .title {
            width: 100%;
            color: #000;
            font-size: 25px;
            font-weight: 600;
            text-align: center;
        }
    }

    .body {
        display: flex;
        padding: 2rem;
        padding-top: 0;
        align-items: center;
        flex-direction: column;

        p {
            width: 100%;
            color: #000;
            font-size: 25px;
            font-weight: 600;
            text-align: center;
            margin-bottom: 0.5rem;
        }

        .group {
            width: 100%;
            margin: 0 0 1.5rem;

            label {
                font-weight: 500;
            }
        }

        .buttons {
            gap: 5px;
            display: flex;
            align-items: center;
            justify-content: center;

            button {
                flex: 0 0 10rem;
            }

            .submit {
                color: #fff;
                background-color: #034294;
                font-weight: 700;
                border-radius: 10px;
            }

            .close {
                font-size: 15px;
                color: #1E1E1E;
                font-weight: 700;
                border-radius: 10px;
                background: #CBCBCB;
                border-color: #CBCBCB;
            }
        }
    }
}