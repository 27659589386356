.header {
    display: flex;
    justify-content: space-between;

    .text {
        h4 {
            margin: 0 0 5px;
            font-weight: 500;
            color: #E93423;
        }

        p {
            margin: 0;
            color: #A7A7A7;
            font-size: 12px;
            font-weight: 500;

            span {
                font-size: 12px;
                color: #E93423;
                font-weight: 500;
            }
        }
    }

    .actions {
        gap: 1rem;
        display: flex;
        align-items: center;

        button {
            gap: 5px;
            width: 10rem;
            height: 40px;
            border: none;
            display: flex;
            font-size: 12px;
            align-items: center;
            justify-content: center;
            background-color: #E93423;

            p {
                margin: 0;
            }

            svg {
                height: 10px;
            }

            &:active,
            &:focus {
                background-color: #E93423;
            }
        }

        .search {
            position: relative;

            input {
                height: 40px;
                font-size: 12px;
                background-color: #EAEDF0;

                &::placeholder {
                    font-size: 12px;
                    color: #969696;
                }
            }

            svg {
                top: 0;
                bottom: 0;
                right: 7px;
                margin: auto;
                height: 13px;
                position: absolute;
            }
        }
    }
}

.buttons {
    display: flex;
    width: 25rem;

    button {
        flex: 1;
        color: #E93423;
        font-weight: bold;
        background-color: #fff;
        border: 1px solid #707070;

        &.active {
            color: #fff;
            border-color: #E93423;
            background-color: #E93423;
        }

        &:hover {
            color: #fff;
            border-color: #E93423;
            background-color: #E93423;
        }
    }
}

.viewRider {
    border-radius: 0 15px 15px 15px;
    padding: 1.5rem 2rem;
    background-color: #fff;
    box-shadow: 0px 3px 15px #A8A5E35F;
}