.productHeader {
    color: #E93423;
    font-weight: 500;

    span {
        display: block;
        font-size: 12px;
        color: #969696;
    }
}

.form {
    gap: 1rem;
    display: flex;
    margin: 0 0 2rem;
    align-items: flex-start;

    .photo {
        flex: 1;
        gap: 1rem;
        height: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: center;

        @media only screen and (max-width: 1199px){
            flex-direction: column;
        }

        span {
            font-size: 12px;
        }


        .image {
            flex: 0 0 12rem;
            width: 100%;

            div {
                height: 100%;
            }
        }
    }

    .column {
        flex: 1;
        gap: 10px;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .fromGroup,
        .fromGroupDescrpition {
            gap: 10px;
            width: 100%;
            display: flex;
            align-items: center;
            
            @media only screen and (max-width: 767px){
                flex-direction: column;
            }

            label {
                flex: 1;
                margin: 0;
                font-size: 12px;
            }

            input,
            textarea {
                flex: 2;
                border: none;
                background-color: #EAEDF0;
            }
        }
    }
}

.actions {
    gap: 1rem;
    display: flex;
    align-items: center;

    button {
        gap: 10px;
        display: flex;
        font-size: 14px;
        align-items: center;

        &.add {
            color: #fff;
            border-color: #E93423;
            background-color: #E93423;
        }

        &.cancel {
            color: #E93423;
            border-color: #fff;
            background-color: #fff;
            box-shadow: 0px 3px 6px #00000029;
        }
    }
}