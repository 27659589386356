.header {
    display: flex;
    justify-content: space-between;

    .text {
        h4 {
            margin: 0 0 5px;
            font-weight: 500;
            color: #E93423;
        }

        p {
            margin: 0;
            color: #A7A7A7;
            font-size: 12px;
            font-weight: 500;

            span {
                font-size: 12px;
                color: #E93423;
                font-weight: 500;
            }
        }
    }

    .actions {
        gap: 1rem;
        display: flex;
        align-items: center;

        button {
            gap: 5px;
            width: 10rem;
            height: 40px;
            border: none;
            display: flex;
            font-size: 12px;
            align-items: center;
            justify-content: center;
            background-color: #E93423;

            p {
                margin: 0;
            }

            svg {
                height: 10px;
            }

            &:active,
            &:focus {
                background-color: #E93423;
            }
        }

        .search {
            position: relative;

            input {
                height: 40px;
                font-size: 12px;
                background-color: #EAEDF0;

                &::placeholder {
                    font-size: 12px;
                    color: #969696;
                }
            }

            svg {
                top: 0;
                bottom: 0;
                right: 7px;
                margin: auto;
                height: 13px;
                position: absolute;
            }
        }
    }
}

.AddMerchant {
    border-radius: 15px;
    padding: 1.5rem 2rem;
    background-color: #fff;
    box-shadow: 0px 3px 15px #A8A5E35F;

    .productHeader {
        color: #E93423;
        font-weight: 500;

        span {
            display: block;
            font-size: 12px;
            color: #969696;
        }
    }

    .form {
        gap: 1rem;
        display: flex;
        margin: 0 0 2rem;
        align-items: flex-start;

        .photo {
            flex: 1;
            gap: 1rem;
            height: 100%;
            display: flex;
            align-items: flex-start;

            img {
                width: 10rem;
            }
        }

        .column {
            flex: 1;
            gap: 10px;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            .fromGroup,
            .fromGroupDescrpition {
                gap: 10px;
                width: 100%;
                display: flex;
                align-items: center;

                label {
                    flex: 1;
                    margin: 0;
                }

                input,
                textarea,
                select {
                    flex: 2;
                    border: none;
                    background-color: #EAEDF0;
                }
            }
        }
    }

    .actions {
        gap: 1rem;
        display: flex;
        margin: 2rem 0 0;
        align-items: center;

        button {
            gap: 10px;
            display: flex;
            font-size: 14px;
            align-items: center;

            &.add {
                color: #fff;
                border-color: #E93423;
                background-color: #E93423;
            }

            &.cancel {
                color: #E93423;
                border-color: #fff;
                background-color: #fff;
                box-shadow: 0px 3px 6px #00000029;
            }
        }
    }
}

.table {
    .tableHeader {
        color: #E93423;
        font-weight: 500;

        span {
            display: block;
            font-size: 12px;
            color: #969696;
        }
    }

    .table {
        margin: 0 0 2rem;

        thead {
            tr {
                border-color: #454545;
                background-color: #454545;

                th {
                    color: #fff;
                    font-size: 13px;
                    font-weight: 400;
                }
            }
        }

        tbody {
            tr {
                td {
                    vertical-align: middle;

                    img {
                        width: 3rem;
                    }

                    .switch {
                        input {
                            cursor: pointer;
                            border-color: #E93423;
                            background-color: #E93423;
                            background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23fff%27/%3e%3c/svg%3e");

                            &:checked {
                                border-color: #21CE50;
                                background-color: #21CE50;
                            }
                        }
                    }

                    .action {
                        fill: #B5B5B5;
                        width: 2rem;
                        height: 2rem;
                        cursor: pointer;
                    }

                    span {
                        margin: 0;

                        &.Available,
                        &.Active {
                            color: #21CE50;
                        }

                        &.Pending {
                            color: #FFC21D;
                        }

                        &.Ongoing {
                            color: #1A88D8;
                        }

                        &.Unavailable {
                            color: #E93423;
                        }
                    }
                }

                &:nth-of-type(odd)>* {
                    border-color: #F4F4F7;
                }

                &:nth-of-type(even)>* {
                    border-color: #fff;
                }
            }
        }
    }
}