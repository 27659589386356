.thumbnail-upload {
    width: 100%;
    max-width: 31rem;

    .upload-thumbnail-preview {
        border: none;
        display: block;
        cursor: pointer;
        border-radius: 8px;
        position: relative;
        padding-top: 9rem;
        background: #EAEDF0;
        // border: 0.5px solid #D0CFCF;

        .center-data {
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            gap: 10px;
            margin: auto;
            display: flex;
            font-size: 10px;
            color: #AFAFAF;
            font-weight: 400;
            position: absolute;
            align-items: center;
            justify-content: center;
            flex-direction: column;
        }
    }

    .thumbnail-preview {
        margin: 0;
        width: 100%;
        overflow: hidden;
        position: relative;
        padding-top: 9rem;
        border-radius: 8px;
        border: 0.5px solid #D0CFCF;

        img {
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            width: 100%;
            height: 100%;
            cursor: pointer;
            position: absolute;
        }
    }

    input {
        display: none;
    }
}