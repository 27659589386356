.header {
    display: flex;
    justify-content: space-between;

    .text {
        h4 {
            margin: 0 0 5px;
            font-weight: 500;
            color: #E93423;
        }

        p {
            margin: 0;
            color: #A7A7A7;
            font-size: 12px;
            font-weight: 500;

            span {
                font-size: 12px;
                color: #E93423;
                font-weight: 500;
            }
        }
    }

    .actions {
        gap: 1rem;
        display: flex;
        align-items: center;

        button {
            gap: 5px;
            width: 10rem;
            height: 40px;
            border: none;
            display: flex;
            font-size: 12px;
            align-items: center;
            justify-content: center;
            background-color: #E93423;

            p {
                margin: 0;
            }

            svg {
                height: 10px;
            }

            &:active,
            &:focus {
                background-color: #E93423;
            }
        }

        .search {
            position: relative;

            input {
                height: 40px;
                font-size: 12px;
                background-color: #EAEDF0;

                &::placeholder {
                    font-size: 12px;
                    color: #969696;
                }
            }

            svg {
                top: 0;
                bottom: 0;
                right: 7px;
                margin: auto;
                height: 13px;
                position: absolute;
            }
        }
    }
}

.viewTransaction {
    border-radius: 15px;
    padding: 1.5rem 2rem;
    background-color: #fff;
    box-shadow: 0px 3px 15px #A8A5E35F;

    .productHeader {
        color: #E93423;
        font-weight: 500;

        span {
            display: block;
            font-size: 12px;
            color: #969696;
        }
    }

    .form {
        gap: 5rem;
        display: flex;
        margin: 0 0 2rem;
        align-items: flex-start;

        .column {
            gap: 10px;
            height: 100%;
            display: flex;
            flex: 0 0 25rem;
            flex-direction: column;
            align-items: flex-start;
            
            .photo {
                flex: 1;
                gap: 1rem;
                height: 100%;
                display: flex;
                align-items: flex-start;
    
                img {
                    width: 10rem;
                    border-radius: 10px;
                }
            }

            @media only screen and (max-width: 991px){
                flex: 1;
            }

            .fromGroup,
            .fromGroupDescrpition {
                gap: 10px;
                width: 100%;
                display: flex;
                align-items: center;

                @media only screen and (max-width: 991px){
                    flex-direction: column;
                }

                label {
                    flex: 1;
                    margin: 0;
                    font-size: 14px;
                    font-weight: 500;
                    color: #3C3C3C;
                }

                input,
                textarea,
                select {
                    border: none;
                    height: 39.5px;
                    flex: 0 0 17rem;
                    font-size: 13px;
                    background-color: #EAEDF0;

                    @media only screen and (max-width: 991px){
                        flex: 1;
                    }
                }
            }
        }
    }

    .actions {
        gap: 1rem;
        display: flex;
        align-items: center;

        button {
            gap: 10px;
            display: flex;
            font-size: 14px;
            align-items: center;

            &.add {
                color: #fff;
                border-color: #E93423;
                background-color: #E93423;
            }

            &.cancel {
                color: #E93423;
                border-color: #fff;
                background-color: #fff;
                box-shadow: 0px 3px 6px #00000029;
            }
        }
    }

    .table {
        margin: 0 0 2rem;

        thead {
            tr {
                border-color: #454545;
                background-color: #454545;

                th {
                    color: #fff;
                    font-size: 13px;
                    font-weight: 400;
                }
                &:nth-of-type(odd)>* {
                    border-color: #F4F4F7;
                }

                &:nth-of-type(even)>* {
                    border-color: #fff;
                }
            }
        }
    }
}