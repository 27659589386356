.navigation-bar {
  .right-container {
    nav {
      .user-account {
        width: 30px;
        height: 30px;

        button {
          width: 30px;
          height: 30px;
        }
      }

      .navbar-brand {
        gap: 1rem;
        display: flex;
        align-items: center;
      }
    }
  }

  .main-container {
    padding: 2rem 0;
  }
}
