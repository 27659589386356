.orderDetails {

    .header {
        border: none;
        align-items: center;
        justify-content: center;
        background-color: #fff;

        .title {
            margin: 0;
            width: 100%;
            color: #000;
            font-size: 25px;
            font-weight: 600;
            text-align: center;
        }
    }

    .body {
        padding: 0;
        height: 100%;
        display: flex;

        .leftContainer {
            flex: 0 0 33rem;
            position: relative;

            .orderDetails {
                padding: 2rem;
                height: 480px;
                overflow-y: auto;

                /* width */
                &::-webkit-scrollbar {
                    width: 10px;
                }

                /* Track */
                &::-webkit-scrollbar-track {
                    background: #f1f1f1;
                }

                /* Handle */
                &::-webkit-scrollbar-thumb {
                    border-radius: 23px;
                    background: rgba(186, 41, 27, 0.3);
                }

                /* Handle on hover */
                &::-webkit-scrollbar-thumb:hover {
                    background: rgba(186, 41, 27, 0.9);
                }

                .header {
                    gap: 10px;
                    width: 100%;
                    display: flex;
                    margin: 0 0 2rem;
                    align-items: center;
                    justify-content: flex-start;

                    svg {
                        width: auto;
                        height: 2rem;
                    }

                    p {
                        margin: 0;
                        font-size: 1.2rem;
                        font-weight: 500;
                        color: #BA291B;
                    }
                }

                .orderDetailsBody {
                    gap: 1.5rem;
                    display: flex;
                    max-width: 390px;
                    flex-direction: column;
                    align-items: flex-start;

                    .list {
                        gap: 10px;
                        display: flex;
                        padding: 1rem;
                        background: #FFF;
                        position: relative;
                        border-radius: 11px;
                        box-shadow: 0px 2px 11px 5px rgba(214, 214, 214, 0.50);

                        .number {
                            margin: 0;
                            top: -17px;
                            left: -16px;
                            color: #fff;
                            display: block;
                            font-size: 14px;
                            padding: 2px 12px;
                            position: absolute;
                            border-radius: 5px;
                            background: #BA291B;
                        }

                        .image {
                            width: 3rem;
                            height: 3rem;
                            object-fit: cover;
                            border-radius: 8px;
                        }

                        .title {
                            h5 {
                                margin: 0 0 1rem;
                                font-size: 18px;
                                color: #BA291B;
                                font-weight: 400;
                                line-height: 1;
                            }

                            .addOns {
                                h6 {
                                    color: #BA291B;
                                    font-weight: 600;
                                }

                                ul {
                                    padding-left: 1rem;

                                    li {
                                        font-size: 14px;
                                        color: #BA291B;
                                    }
                                }
                            }
                        }

                        .quantity {
                            margin: 0;
                            font-size: 18px;
                            line-height: 1;
                            color: #BA291B;
                            font-weight: 400;
                        }
                    }
                }
            }

            .addressOnMap {
                height: auto;
                width: 100%;
            }

            .footer {
                gap: 2rem;
                width: 100%;
                padding: 1rem;
                display: flex;
                align-items: center;
                background: #FFEBB4;
                justify-content: center;

                button {
                    gap: 5px;
                    border: none;
                    font-size: 14px;
                    display: flex;
                    align-items: center;
                    border-radius: 90px;

                    svg {
                        width: 2rem;
                        height: 2rem;
                    }
                }

                .cancel {
                    color: #fff;
                    background: #BA291B;
                }

                .accept {
                    background: #fff;
                    color: #BA291B;
                }

                .prepared {
                    margin: 0;
                    font-weight: bold;
                    color: rgb(0, 78, 0);
                    text-transform: uppercase;
                }

                .preparing {
                    margin: 0;
                    font-weight: bold;
                    color: rgb(0, 150, 0);
                    text-transform: uppercase;
                }

                .pending {
                    margin: 0;
                    color: #FFC21D;
                    font-weight: bold;
                    text-transform: uppercase;
                }

                .sending {
                    margin: 0;
                    font-weight: bold;
                    text-transform: uppercase;
                }
            }

            .selection {
                top: 2rem;
                gap: 1rem;
                right: 20px;
                display: flex;
                position: absolute;
                flex-direction: column;

                .data {
                    cursor: pointer;
                    text-align: center;

                    .image {
                        text-align: center;
                        background: #FFF;
                        border-radius: 1.5rem;
                        box-shadow: 0px 2px 4px 0px rgba(100, 100, 100, 0.50);

                        img {
                            width: 4rem;
                            height: 4rem;
                            object-fit: cover;
                        }

                        &.active {
                            background: #BA291B;
                        }
                    }

                    p {
                        margin: 0;
                        font-size: 12px;
                    }
                }
            }
        }

        .rightContainer {
            flex: 1;
            padding: 2rem 1rem;
            background: #BA291B;

            .closemodal {
                top: 12px;
                right: 12px;
                width: 1.5rem;
                height: 1.5rem;
                cursor: pointer;
                position: absolute;
            }

            .orderHeader {
                gap: 10px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0 0 1rem;

                img {
                    height: 2.5rem;
                }

                p {
                    color: #fff;
                    margin: 0;
                }
            }

            .customer {
                display: flex;
                align-items: center;
                flex-direction: column;

                img {
                    width: 5rem;
                    height: 5rem;
                    object-fit: cover;
                    border-radius: 50%;
                    margin: 0 0 10px;
                }

                h3 {
                    color: #fff;
                    font-size: 1rem;
                    font-weight: 400;
                    text-align: center;
                }

                h4 {
                    color: #fff;
                    font-weight: 400;
                    font-size: 14px;
                    text-align: center;
                }

                p {
                    color: #fff;
                    font-weight: 400;
                    margin: 0 0 5px;
                    font-size: 12px;
                    text-align: center;

                    &.distance {
                        color: #4DF;
                    }
                }
            }

            .line {
                height: 3px;
                opacity: 1;
                width: 100%;
                border: none;
                background-color: #fff;
            }

            .orderSummary {
                .summaryHeader {
                    gap: 5px;
                    display: flex;
                    margin: 0 0 10px;
                    align-items: center;

                    img {
                        height: 2rem;
                    }

                    p {
                        margin: 0;
                        color: #fff;
                        font-size: 14px;
                        font-weight: normal;

                        span {
                            display: block;
                            font-size: 12px;
                            font-weight: 300;
                        }
                    }
                }

                .summaryBody {
                    gap: 10px;
                    display: flex;
                    flex-direction: column;

                    .row {
                        display: flex;
                        justify-content: space-between;

                        p {
                            margin: 0;
                            color: #fff;
                            font-size: 14px;
                            font-weight: 300;
                        }
                    }

                    .total {
                        display: flex;
                        justify-content: space-between;

                        p {
                            margin: 0;
                            color: #fff;
                            font-size: 14px;
                            font-weight: 600;
                        }
                    }

                    .summaryTotalLine {
                        height: 1px;
                        margin: 0;
                        opacity: 1;
                        width: 100%;
                        border: none;
                        background-color: #fff;
                    }
                }
            }
        }
    }
}