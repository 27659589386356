.header {
    display: flex;
    justify-content: space-between;

    .text {
        h4 {
            margin: 0 0 5px;
            font-weight: 500;
            color: #E93423;
        }

        p {
            margin: 0;
            color: #A7A7A7;
            font-size: 12px;
            font-weight: 500;

            span {
                font-size: 12px;
                color: #E93423;
                font-weight: 500;
            }
        }
    }

    .actions {
        gap: 1rem;
        display: flex;
        align-items: center;

        button {
            gap: 5px;
            width: 10rem;
            height: 40px;
            border: none;
            display: flex;
            font-size: 12px;
            align-items: center;
            justify-content: center;
            background-color: #E93423;

            p {
                margin: 0;
            }

            svg {
                height: 10px;
            }

            &:active,
            &:focus {
                background-color: #E93423;
            }
        }

        .search {
            position: relative;

            input {
                height: 40px;
                font-size: 12px;
                background-color: #EAEDF0;

                &::placeholder {
                    font-size: 12px;
                    color: #969696;
                }
            }

            svg {
                top: 0;
                bottom: 0;
                right: 7px;
                margin: auto;
                height: 13px;
                position: absolute;
            }
        }
    }
}



.productHeader {
    color: #E93423;
    font-weight: 500;

    span {
        display: block;
        font-size: 12px;
        color: #969696;
    }
}

.form {
    gap: 1rem;
    display: flex;
    margin: 0 0 2rem;
    align-items: flex-start;

    .photo {
        flex: 1;
        gap: 1rem;
        height: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: center;

        span {
            font-size: 12px;
        }


        .image {
            width: 100%;
            flex: 0 0 12rem;
            margin: 0 0 2rem;

            div {
                height: 100%;
            }

            img {
                width: 14rem;
            }
        }
    }

    .column {
        flex: 1;
        gap: 10px;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .fromGroup,
        .fromGroupDescrpition {
            gap: 10px;
            width: 100%;
            display: flex;
            align-items: center;

            label {
                flex: 1;
                margin: 0;
                font-size: 12px;
            }

            input,
            textarea {
                flex: 2;
                border: none;
                background-color: #EAEDF0;
            }
        }
    }
}

.actions {
    gap: 1rem;
    display: flex;
    align-items: center;

    button {
        gap: 10px;
        display: flex;
        font-size: 14px;
        align-items: center;

        &.add {
            color: #fff;
            border-color: #E93423;
            background-color: #E93423;
        }

        &.cancel {
            color: #E93423;
            border-color: #fff;
            background-color: #fff;
            box-shadow: 0px 3px 6px #00000029;
        }
    }
}