.container {
  @media only screen and (max-width: 969px) {
    padding: 0 !important;
  }
}

.loginContainer {
  width: 100%;
  height: 100vh;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;

  .loginForm {
    gap: 1rem;
    height: 100%;
    margin: auto;
    flex: 0 0 35%;
    display: flex;
    padding: 2rem 3rem;
    border-radius: 1rem;
    flex-direction: column;
    justify-content: center;

    @media only screen and (max-width: 969px) {
      z-index: 1;
    }

    img {
      width: 7rem;
      margin: 0 auto 1rem;
    }

    label {
      font-size: 15px;
      font-weight: 500;
      line-height: 22px;
    }

    .loginHeader {
      display: flex;
      align-items: center;
      flex-direction: column;

      h6 {
        margin: 0;
        color: #575757;
        font-weight: 500;
        font-size: 1.7rem;
      }

      p {
        font-size: 1.1rem;
        color: #575757;
        text-align: center;
        font-weight: 500;
      }
    }

    .formContainer {
      gap: 1rem;
      width: 100%;
      display: flex;
      margin: 0 auto;
      flex-direction: column;
    }

    .formContainer.formHorizontal {
      align-items: center;
      flex-direction: row;
      justify-content: flex-end;

      .rememberMe {
        color: #878787;

        input {
          margin-left: -1.3em;
        }

        label {
          font-size: 13px;
          font-weight: 500;
        }
      }
    }

    .forgotPassword {
      font-size: 13px;
      color: #D1002A;
      font-weight: 500;
      text-align: right;
      text-decoration: none;
    }

    .marginContainer {
      gap: 1rem;
      width: 100%;
      display: flex;
      margin: 1rem auto 0;
      flex-direction: column;

      button {
        width: 100%;
      }

      .submitButton {
        height: 41px;
        font-size: 15px;
        font-weight: 400;
        border-radius: 8px;
        border-color: #E93423;
        background-color: #E93423;
      }

      .signup {
        gap: 10px;
        display: flex;
        align-items: center;
        justify-content: center;

        p {
          margin: 0;
          padding: 0;
          font-size: 12px;
        }

        .link {
          font-size: 12px;
          color: #D1002A;
          font-weight: 500;
          text-decoration: none;
        }
      }
    }
  }

  .imageContainer {
    height: 100%;
    flex: 0 0 65%;
    text-align: right;

    img {
      height: 100%;
      object-fit: cover;
    }
  }

  .copyright {
    margin: 0;
    bottom: 25px;
    color: #878787;
    font-size: 13px;
    font-weight: 500;
    position: absolute;
    position: absolute;
  }

  @media (max-width: 1400px) {
    .loginForm {}

    .imageContainer {}
  }

  @media (max-width: 1200px) {
    .loginForm {
      flex: 1;
    }

    .imageContainer {
      flex: 2;

      img {
        width: 100%;
        height: 100%;
        object-position: left;
      }
    }
  }

  @media (max-width: 969px) {
    width: auto;

    .loginForm {
      flex: 1 1;
      height: auto;
      margin: 0 3rem;
      background: rgba(255, 255, 255, 0.8);
      box-shadow: 0 3px 6px rgba(0, 0, 0, .161);

      .loginHeader {
        h6 {
          font-size: 1rem;
        }

        p {
          font-size: 29px;
          line-height: 1;
        }
      }

      .marginContainer {
        .submitButton {
          height: 56px;
        }

        .signup {
          gap: 10px;
          display: flex;
          align-items: center;
          justify-content: center;

          p {
            margin: 0;
            padding: 0;
            font-size: 12px;
          }

          .link {
            font-size: 12px;
            color: #D1002A;
            font-weight: 500;
            text-decoration: none;
          }
        }
      }

      .copyright {
        left: 0;
        right: 0;
        margin: auto;
        color: #fff;
        text-align: center;
      }
    }

    .imageContainer {
      top: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      display: block;
      position: absolute;

      img {
        top: 0;
        bottom: 0;
        z-index: -1;
        height: 100%;
        left: 0;
        width: 100%;
        position: absolute;
        object-position: right;
      }
    }
  }

  @media (max-width: 767px) {
    .loginForm {
      margin: 0 1rem;
      padding: 1.5rem;
    }
  }
}