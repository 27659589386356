.productHeader {
    color: #E93423;
    font-weight: 500;

    span {
        display: block;
        font-size: 12px;
        color: #969696;
    }
}

.table {
    .tableHeader {
        color: #E93423;
        font-weight: 500;

        span {
            display: block;
            font-size: 12px;
            color: #969696;
        }
    }

    .table {
        margin: 0 0 2rem;

        thead {
            tr {
                border-color: #454545;
                background-color: #454545;

                th {
                    color: #fff;
                    font-size: 13px;
                    font-weight: 400;
                }
            }
        }

        tbody {
            tr {
                td {
                    font-size: 12px;
                    font-weight: 500;
                    vertical-align: middle;

                    img {
                        width: 3rem;
                    }

                    .switch {
                        input {
                            cursor: pointer;
                            border-color: #E93423;
                            background-color: #E93423;
                            background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23fff%27/%3e%3c/svg%3e");

                            &:checked {
                                border-color: #21CE50;
                                background-color: #21CE50;
                            }
                        }
                    }

                    .action {
                        fill: #B5B5B5;
                        width: 2rem;
                        height: 2rem;
                        cursor: pointer;
                    }

                    span {
                        margin: 0;

                        &.Completed,
                        &.delivered {
                            color: #21CE50;
                        }

                        &.order-received {
                            color: #09a833;
                        }

                        &.rider-to-customer {
                            color: #1366a2;
                        }

                        &.Pending,
                        &.pending {
                            color: #FFC21D;
                        }

                        &.Ongoing,
                        &.prepared,
                        &.preparing {
                            color: #1A88D8;
                        }

                        &.Cancelled,
                        &.cancelled {
                            color: #E93423;
                        }
                    }
                }

                &:nth-of-type(odd)>* {
                    border-color: #F4F4F7;
                }

                &:nth-of-type(even)>* {
                    border-color: #fff;
                }
            }
        }

        tfoot {
            tr {
                td {
                    color: #fff;
                    font-size: 12px;
                    font-weight: 500;
                    vertical-align: middle;
                    border-color: #454545;
                    background-color: #454545;
                }
            }
        }
    }
}