.top-bar {
  z-index: 99;
  position: relative;

  .brand-container {
    gap: 1rem;
    display: flex;
    align-items: center;

    img {
      width: 3rem;
    }

    p {
      margin: 0;
      font-size: 16px;
      font-weight: 500;
      color: #E93423;
    }
  }

  .user-account {
    gap: 1rem;
    display: flex;
    align-items: center;

    p {
      margin: 0;
      font-size: 16px;
      font-weight: 500;
      color: #E93423;
      text-transform: uppercase;
    }

    img {
      width: 3rem;
      border-radius: 50%;
    }

    svg {
      width: 20px;
      cursor: pointer;
    }
  }
}

.red-bar {
  background-color: #E93423;

  #navbar-nav {
    .navbar-nav {
      gap: 1rem;

      .nav-link {
        gap: 10px;
        color: #fff;
        display: flex;
        font-size: 13px;
        font-weight: 400;
        position: relative;
        align-items: center;
        text-transform: uppercase;

        svg {
          height: 18px;
        }

        &.active {
          &::after {
            content: '';
            width: 100%;
            height: 5px;
            bottom: -8px;
            position: absolute;
            border-radius: 6px 6px 0 0;
            background-color: #9F0F01;
          }
        }
      }
    }
  }
}