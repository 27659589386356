.header {
    display: flex;
    justify-content: space-between;

    .text {
        h4 {
            margin: 0 0 5px;
            font-weight: 500;
            color: #E93423;
            text-transform: uppercase;
        }

        p {
            margin: 0;
            color: #A7A7A7;
            font-size: 12px;
            font-weight: 500;

            span {
                font-size: 12px;
                color: #E93423;
                font-weight: 500;
            }
        }
    }

    .actions {
        gap: 1rem;
        display: flex;
        align-items: center;

        @media only screen and (max-width: 991px) {
            flex-direction: column;
            align-items: flex-end;
        }

        p {
            margin: 0;
            font-size: 12px;
            color: #A7A7A7;
        }

        button {
            gap: 5px;
            width: 11rem;
            height: 40px;
            border: none;
            display: flex;
            font-size: 12px;
            align-items: center;
            justify-content: center;
            background-color: #E93423;

            p {
                margin: 0;
                color: #fff;
            }

            svg {
                height: 10px;
            }

            &:active,
            &:focus {
                background-color: #E93423;
            }
        }

        button.filter {
            color: #E93423;
            background-color: #EAEDF0;
        }

        .search {
            position: relative;

            input {
                height: 40px;
                font-size: 12px;
                background-color: #EAEDF0;

                &::placeholder {
                    font-size: 12px;
                    color: #969696;
                }
            }

            svg {
                top: 0;
                bottom: 0;
                right: 7px;
                margin: auto;
                height: 13px;
                position: absolute;
            }
        }
    }
}

.deliveries {
    gap: 1rem;
    width: 100%;
    height: 100%;
    padding: 2rem;
    display: flex;
    border-radius: 15px;
    align-items: center;
    flex-direction: column;
    background-size: cover;
    justify-content: center;
    background-blend-mode: overlay;
    box-shadow: 0px 3px 15px #A8A5E35F;
    background-color: rgb(0 0 0 / 37%);
    background-image: url('../../assets/images/login Image.png');

    p {
        margin: 0;
        color: #fff;
        font-size: 18px;
        line-height: 1.2;
        font-weight: 600;
        text-align: center;

        span {
            display: block;
            font-size: 3.5rem;
        }
    }
}

.currentDeliveries {
    display: flex;
    flex-direction: column;

    .header {
        display: flex;
        margin: 0 0 1rem;
        align-items: center;
        justify-content: space-between;


        h5 {
            margin: 0;
            color: #1F1F1F;
        }

        button {
            gap: 5px;
            width: 11rem;
            height: 40px;
            border: none;
            display: flex;
            font-size: 12px;
            align-items: center;
            justify-content: center;
            background-color: #E93423;

            p {
                margin: 0;
                color: #fff;
            }

            svg {
                height: 10px;
            }

            &:active,
            &:focus {
                background-color: #E93423;
            }
        }
    }

    .cardDatas {
        gap: 1.5rem;
        display: grid;
        grid-template-columns: auto auto auto;

        @media only screen and (max-width: 991px) {
            grid-template-columns: auto auto;
        }

        @media only screen and (max-width: 767px) {
            grid-template-columns: auto;
        }

        @media only screen and (max-width: 575px) {
            grid-template-columns: auto auto;
        }

        .card {
            flex: 1;
            padding: 2rem 1rem;
            background: #FFF;
            border-radius: 15px;
            box-shadow: 0px 3px 15px #A8A5E35F;

            h6 {
                text-transform: uppercase;
            }

            p {
                gap: 5px;
                display: flex;
                font-weight: 700;
                font-size: 2.5rem;
                align-items: center;

                span {
                    font-size: 13px;
                    font-weight: 600;
                    color: #3C3C3C;
                }

                svg {
                    color: #3C3C3C;
                    font-size: 1rem;
                }
            }

            .progress {
                text-align: right;

                .pendingBar {
                    height: 14px;
                    border-radius: 20px;
                    --bs-progress-bar-bg: #FFC21D;
                }

                .completedBar {
                    height: 14px;
                    border-radius: 20px;
                    --bs-progress-bar-bg: #21CE50;
                }

                .cancelledBar {
                    height: 14px;
                    border-radius: 20px;
                    --bs-progress-bar-bg: #E93423;
                }
            }

            &.pending {
                h6 {
                    color: #FFC21D;
                }


                p {
                    color: #FFC21D;
                }
            }

            &.completed {
                h6 {
                    color: #21CE50;
                }


                p {
                    color: #21CE50;
                }
            }

            &.cancelled {
                h6 {
                    color: #E93423;
                }


                p {
                    color: #E93423;
                }
            }
        }
    }
}

.merchantList {
    padding: 1rem 2rem;
    border-radius: 15px;
    box-shadow: 0px 3px 15px #A8A5E35F;
    background: #FFFFFF 0% 0% no-repeat padding-box;

    .merchantHeader {
        display: flex;
        margin: 0 0 1rem;
        align-items: center;
        justify-content: space-between;

        p {
            margin: 0;
            font-size: 1rem;
            color: #E93423;
            font-weight: bold;

            span {
                display: block;
                font-size: 12px;
                color: #969696;
                font-weight: normal;
            }
        }

        svg {
            cursor: pointer;
        }
    }

    .list {
        gap: 1.5rem;
        display: flex;
        flex-direction: column;

        .data {
            gap: 10px;
            display: flex;

            @media only screen and (max-width: 767px) {
                flex-direction: column;
            }

            @media only screen and (max-width: 575px) {
                flex-direction: row;
            }

            .image {
                img {
                    width: 4rem;
                    height: 4rem;
                    object-fit: cover;
                    border-radius: 50%;
                }
            }

            .body {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;

                p {
                    margin: 0;
                    font-size: 14px;
                    font-weight: bold;
                    text-transform: uppercase;

                    span {
                        display: block;
                        font-size: 12px;
                        color: #969696;
                        font-weight: normal;
                        text-transform: capitalize;
                    }
                }

                svg {
                    cursor: pointer;
                }
            }
        }
    }
}

.chart {
    padding: 1rem 2rem;
    border-radius: 15px;
    box-shadow: 0px 3px 15px #A8A5E35F;
    background: #FFFFFF 0% 0% no-repeat padding-box;

    .header {
        display: flex;
        margin: 0 0 1rem;
        align-items: center;
        justify-content: space-between;

        p {
            margin: 0;
            font-size: 1rem;
            color: #E93423;
            font-weight: bold;

            span {
                display: block;
                font-size: 12px;
                color: #969696;
                font-weight: normal;
            }
        }

        .selectContainer {
            position: relative;

            select {
                font-size: 12px;
                font-weight: 500;
                color: #E93423;
                appearance: none;
                padding-right: 2em;
                -moz-appearance: none;
                -webkit-appearance: none;
                text-transform: uppercase;
                background-size: 10px 10px;
                background-color: #F6DAD8;
                background-repeat: no-repeat;
                background-position: 94% center;
                background-image: url('../../assets/svg/calendar\ red.svg');
            }
        }
    }
}